import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
:root {
    --primary: #f3c20e;
    --black: #2E2E2E;
    --red: #C8553D;
    --green: #436b6a;
  }
body {
    
    background-image: url("https://www.transparenttextures.com/patterns/bedge-grunge.png");
    /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}
img {
  max-width: 100%;
}
nav a {
  margin-right: 5vw;
  font-family: Yantramanav;
  font-size: 1.8rem;
  text-decoration: underline var(--primary);
}
.gatsby-image-wrapper img[src*=base64\\,] {
  image-rendering: -moz-crisp-edges;
}
.footer{
  font-family: Yantramanav;
  font-size: 1.8rem;
  text-decoration: underline var(--primary);
}
.quote {
  font-style: italic;
}
.poetry {
  font-weight: 800;
}
.nospace {
  margin-top: 0;
  margin-bottom: 0;
}
.ref {
  font-weight: 800;
  font-style: italic;
  margin-top: 0;
}
.nobottom {
  margin-bottom: 0;
}
br::selection, a::selection, h1::selection, p::selection {
  background-color: var(--primary);
  color: white;
}
`;

export default GlobalStyles;
