import { createGlobalStyle } from "styled-components";

import headingFont from "../assets/fonts/Yantramanav.ttf";
import font from "../assets/fonts/NotoSans.ttf";

const Typography = createGlobalStyle`
  @font-face {
    font-family: Yantramanav, cursive;
    src: url(${headingFont});
  }
  @font-face {
    font-family: 'Noto Sans', sans-serif;
    src: url(${font})
  }
  html {
    font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }
  p, li {
    letter-spacing: 0.5px;
    font-size: 1.2em;
    line-height: 1.6;
  }
  h1 {
    font-family: Yantramanav;
    font-weight: bold;
    font-size: 2.5em;
  }
  h3 {
    font-family: Yantramanav;
    font-weight: bold;
    font-size: 1.6em;
  }
  a {
    color: var(--black);
    text-decoration: none;
    font-size: 1.6rem;
  }
  .nav-button {
    background-image: linear-gradient( rgba(255, 255, 255, .0) 50%, #f3c20e 50%);
    background-position-y: 100%;
    padding-top: 5px;
    margin-left: 1vw;
    margin-right: 1vw;
    display: inline;
    line-height: 1;
    transition: background 400ms ease;
    background-size: 4px;
    background-size: auto 175%;
  }
  .nav-button:hover {
    background-position-y: 10%;
    cursor: pointer;
  }
  mark, .mark {
    background: var(--primary);
    padding: 0 2px 2px 2px;
    display: inline;
    line-height: 1;
  }

  .center {
    text-align: center;
  }

  .tilt {
    transform: rotate(-2deg);
  }
`;

export default Typography;
